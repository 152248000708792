/* Add this CSS to your stylesheet */
/* UploadAvatar.css */

.avatar-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two columns for image and buttons */
    gap: 20px;
    /* Adjust the gap as needed */
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Add this to center horizontally */
    text-align: center;
    /* Add this to center text and buttons */
}


.avatar-editor {
    width: 200px;
    /* Set your desired round shape width */
    height: 200px;
    /* Set your desired round shape height */
    border-radius: 50%;
    /* Makes it round */
    overflow: hidden;
}

/* UploadAvatar.css */

.avatar-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two columns for image and buttons */
    gap: 20px;
    /* Adjust the gap as needed */
    align-items: center;
    /* Center vertically */
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center horizontally */
}

.button {
    background-color: #007bff;
    /* Set your desired button color */
    color: #fff;
    /* Text color */
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    /* Adjust spacing as needed */
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
    /* Change color on hover */
}