/* Image inside slider */
.slider-image {
    width: 81%;
    /* Set the desired width for the image */
    max-width: 100%;
    /* Ensure the image doesn't exceed its container */
    display: inline-block;
    /* Make the image inline-block for centering */
    margin: 0 auto;
    /* Center the image horizontally */
    padding: 10px;
    /* Add padding as needed */
    box-sizing: border-box;
    /* Ensure padding is included in width calculation */
}

/* Dot indicators */
.slick-dots li button:before {
    font-size: 16px;
    /* Adjust the font size as needed */
    color: #0099ff !important;
    /* Change the color to blue */
    opacity: 0.3;
    border-radius: 50%;
    /* Create a round shape for the dots */
    border: 4px solid white;
    /* Add a 1px solid white border */
    width: 24px;
    /* Set the width of the dots */
    height: 24px;
    /* Set the height of the dots */

}

/* Text and link below the image */
.slider-filename {
    color: white;
    /* Text color */
    text-align: center;
    /* Center-align the text */
    margin: 10px 0;
    /* Add margin as needed */
}