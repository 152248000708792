.user-profile-container {
    padding-top: 25px;
}

.user-card {

    background: linear-gradient(to right, #5433ff, #20bdff, #a5fecb);
    border: none;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-image img {
    width: 144px;
    height: 144px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.user-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.tab-container {
    background-color: #f8f9fa;
    border-radius: 4px;
    padding: 10px;
}

.tab-links {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
}

.tab-link {
    background-color: #fff;
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.tab-link.active {
    background-color: #007bff;
    color: #fff;
}

.tab-content {
    border: 1px solid #007bff;
    border-radius: 4px;
    padding: 15px;
}

.tab-panel {
    display: block;
}




.user-profile-tab .nav-tabs {
    border-bottom: none;

}

.user-profile-tab .nav-link {
    cursor: pointer;
    border: none;
    border-radius: 6px 6px 0 0;
    /* Set your desired border-radius value here */
    color: #333;
    font-weight: bold;
}

.user-profile-tab .nav-link:hover {
    background-color: #f0f0f0;
}

.user-profile-tab .nav-item.active .nav-link {
    background-color: #007bff;
    color: #fff;
}


.user-profile-tab .nav-link:hover {
    background-color: #f0f0f0;
}

.user-profile-tab .nav-item.active .nav-link {
    background-color: #007bff;
    color: #fff;
}


/* .tab-content {
    border: 1px solid #dee2e6;
    border-top: none;
    padding: 15px;
    background-color: #fff;
    min-height: 300px;
} */


.social-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.social-icons button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f1f1f1;
    margin: 0 5px;
    text-align: center;
    line-height: 40px;
    color: #333;
    font-size: 18px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.social-icons button:hover {
    background-color: #3b5998;
    /* Facebook blue */
    color: #fff;
}

.social-icons button:nth-child(2):hover {
    background-color: #e4405f;
    /* Instagram pink */
}

.social-icons button:nth-child(3):hover {
    background-color: #c4302b;
    /* YouTube red */
}



/* Add padding between slider items */
.tab-pane .slick-slide {
    padding: 12px;
}

/* Change the color of the slider arrows to black */
.slick-prev:before,
.slick-next:before {
    color: black;
}

/* Custom styles for the ImageGallery arrows */
.image-gallery-icon {
    font-size: 24px;
}

.custom-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    /* Adjust the desired height */
    width: 100%;
}

.custom-image {
    max-height: 100%;
    /* Set the desired percentage */
    max-width: 100%;
    object-fit: contain;
}


/* UserProfile.css */

.youtube-video-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.video-wrapper {
    position: relative;
    padding-bottom: 6%;
    /* 16:9 aspect ratio for responsive videos */
    overflow: hidden;
}

.video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}