.stepper {
    position: relative;
    margin-bottom: 20px;
}

.progress-bar {
    background-color: #ccc;
    height: 10px;
    border-radius: 5px;
}

.progress {
    background-color: #304ffe;
    height: 100%;
    border-radius: 5px;
    transition: width 0.3s ease;
}

.steps {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: bold;

}

.steps li {
    flex: 1;
    text-align: center;
    color: #304ffe;

    position: relative;
}

.steps li .step-number {
    width: 30px;
    height: 30px;
    border: 2px solid #0066ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 5px;
    color: #0066ff;
    background-color: #fff;

}

.steps li.active .step-number {
    border-color: #0066ff;
    color: #fff;
    background-color: #0066ff;
}

.steps li .step-description {
    font-size: 12px;
}

.steps li.active .step-description {
    color: #000;
}


.full-width-input {
    width: 100%;
    padding: 6px;
    border-radius: 5px;
}


.input-field {
    position: relative;

}

.input-field input {
    /* outline: none;
    border: none; */
    outline: 1px solid #ccc;
    border: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s ease-in-out;
}

.input-field input:focus {
    border-bottom-color: #0066ff;
    box-shadow: 0 0 5px rgba(0, 0, 255, 0.5);
}

.input-field textarea {
    /* outline: none;
    border: none; */
    outline: 1px solid #ccc;
    border: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s ease-in-out;
    padding: 6px;
    border-radius: 5px;

}

.input-field textarea:focus {
    border-bottom-color: #0066ff;
    box-shadow: 0 0 5px rgba(0, 0, 255, 0.5);
}



.form-step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inline-inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.form-group {
    flex: 1;
    margin-right: 10px;
    /* Adjust the margin between the columns if needed */
}

.input-field {
    width: 100%;
}


.input-label {
    text-align: left;
    margin-top: 9px;
}

.input-field-short {
    width: 210px;
    padding: 6px;
    border-radius: 5px;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


.inline-inputs {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.inline-inputs>div {
    margin-right: 10px;
    /* Adjust the value as per your preference */
}

.input-field-full {
    width: 100%;
    height: 100px;
    padding: 6px;
    border-radius: 5px;
}

.category-options-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 30px;
}

.form-check {
    width: 18%;
    /* 6 items per row (100% divided by 6) */
    /* Adjust margin and padding as needed to add spacing between the options */
    margin: 4px;
    padding: 8px;
}

/* Increase the size of the checkbox */
.form-check-input[type="checkbox"] {
    width: 20px;
    /* Set the width of the checkbox */
    height: 20px;
    /* Set the height of the checkbox */
}

/* Increase the size of the checkbox label text */
.form-check-label {
    font-size: 15px;
    /* Set the font size for the label text */
}

/* Optional: Add custom styles to make the checkboxes more noticeable */
.form-check-input[type="checkbox"] {
    /* Add custom styles for the checkbox, like a border or background color */
    border: 2px solid #0066ff;
    background-color: #fff;
    /* You can also add other styles like rounded corners or shadows */
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

/* Optional: Apply styles when the checkbox is checked */
.form-check-input[type="checkbox"]:checked {
    background-color: #0066ff;
    /* You can change the appearance of the checked checkbox, e.g., add a tick mark */
    /* For example, content: '\2713'; will add a checkmark. */
}