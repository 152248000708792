.footer-dark {
    padding: 30px 0;
    color: #fff;
    background-color: #282d32;
}

.footer-dark h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 16px;
}

.footer-dark ul {
    padding: 0;
    list-style: none;
    line-height: 1.8;
    font-size: 14px;
    margin-bottom: 0;
}

.footer-dark ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.6;
}

.footer-dark ul a:hover {
    opacity: 0.9;
    color: dodgerblue;
}

@media (max-width:767px) {
    .footer-dark .item:not(.social) {
        text-align: center;
        padding-bottom: 20px;
    }
}

.footer-dark .item.text {
    margin-bottom: 36px;
}

@media (max-width:767px) {
    .footer-dark .item.text {
        margin-bottom: 0;
    }
}

.footer-dark .item.text p {
    opacity: 0.6;
    margin-bottom: 0;
}

.footer-dark .item.social {
    text-align: center;
}

@media (max-width:991px) {
    .footer-dark .item.social {
        text-align: center;
        margin-top: 20px;
    }
}

.footer-dark .item.social>a {
    font-size: 20px;
    width: 45px;
    height: 45x;
    line-height: 45px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    background-color: #ff2056;
    /* Set the background color to blue */

    margin: 0 8px;
    color: #fff;

    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
    /* Added transition for smoother color change */
}

.footer-dark .item.social>a:hover {
    opacity: 0.9;
    background-color: royalblue;
    /* Change the background color on hover */
}


.footer-dark .copyright {
    text-align: center;
    padding-top: 24px;
    opacity: 0.3;
    font-size: 13px;
    margin-bottom: 0;
}