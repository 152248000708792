.card {
    padding: 30px 0 40px;
    margin-bottom: 30px;
    background-color: #f7f5ec;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.card .picture {
    margin: 0 auto;
    /* Center align horizontally */
    height: 144px;
    width: 144px;
    margin-bottom: 50px;
    z-index: 1;
    position: relative;
}

.card .picture::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 50%;

    background: linear-gradient(to right, #5433ff, #20bdff, #a5fecb);
    position: absolute;
    bottom: 135%;
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(3);
    transition: all 0.3s linear 0s;
}

.card:hover .picture::before {
    height: 100%;
}

.card .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #3366cc;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.card .picture img {
    display: block;
    margin: 0 auto;
    /* Center align horizontally */
    width: 100%;
    height: auto;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.9s ease 0s;
}

.card:hover .picture img {
    box-shadow: 0 0 0 14px #f7f5ec;
    transform: scale(0.7);
}

.card .title {
    display: block;
    font-size: 15px;
    color: #4e5052;
    text-transform: capitalize;
}

.card .social {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    position: absolute;
    bottom: -100px;
    left: 0;
    transition: all 0.5s ease 0s;
}

.card:hover .social {
    bottom: 0;
}

.card .social li {
    display: inline-block;
}

.card .social li a {
    display: block;
    padding: 10px;
    font-size: 17px;
    color: white;
    transition: all 0.3s ease 0s;
    text-decoration: none;
}

.card .social li a:hover {
    color: #3366cc;
    background-color: #f7f5ec;


}


.arrow-icon {
    font-size: 27px;
    /* Adjust the font size as needed */
    color: #3366cc;
    /* Change the color to your preferred color */
}