.userImgList {


    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 0px;
    width: 39px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.4);
        /* Increase scale on hover */
    }

}




.centered {
    text-align: center;
    vertical-align: middle;
}



.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.navbar {
    background-color: #f8f8f8;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 540px;
    margin: 0 auto;
}

.input-container .form-group {
    width: 100%;
}

@media (max-width: 768px) {
    .input-container {
        max-width: 100%;
    }
}