.header-container {
    position: relative;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* Black with 0.6 opacity */
}



.avatar-group .avatar-group-item {
    display: inline-block;
    position: relative;
    margin-left: -0.4rem;
    border: 1px solid #ccc;
    transition: transform 0.3s ease;
}

.avatar-group .avatar-group-item:hover {
    transform: scale(1.4);
}


.avatar.sm {
    width: 2.70rem;
    height: 2.70rem;
    font-size: .818125rem;
}

.avatar-group {
    display: inline-flex;
    align-items: stretch;
}

.bg-gray {
    --bs-bg-opacity: 1;
    background-color: #003399 !important;
    text-decoration-color: #003399;

}

.card {

    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}


.welcome-image {
    padding: 3;
}



.video-title {
    text-align: center;
}

.outline-text {
    font-weight: bold;
    color: #ffffff;

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* Add shadow effect */
}


.larger-font {
    font-size: 30px;
    /* Adjust the font size as needed */
}

.welcome-text {
    font-weight: bold;
    color: black;

}

.small-font {
    font-size: 24px;
    font-weight: bold;
    /* Adjust the font size as needed */
}



.card-with-shadow {
    box-shadow: 0 9px 18px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.08);
}




.jump {
    animation: jumpAnimation 4s ease-in-out infinite;
    /* Apply the jump animation */
    transform-origin: center bottom;
    /* Set the rotation point to the bottom center of the image */
}

@keyframes jumpAnimation {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
        /* No vertical movement */
    }

    40% {
        transform: translateY(-20px);
        /* Jump up */
    }

    60% {
        transform: translateY(-20px);
        /* Hold the jump position */
    }
}



.zoom {
    animation: zoomAnimation 1s ease-in-out infinite alternate;
    /* Apply the zoom animation */
    transform-origin: center;
    /* Set the scaling origin to the center of the image */
}

@keyframes zoomAnimation {
    0% {
        transform: scale(1);
        /* Normal size */
    }

    100% {
        transform: scale(1.1);
        /* Zoomed in (scale up) */
    }
}