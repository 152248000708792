/* MainPage.css */


body {
    font-family: 'Raleway', sans-serif;
}

/* For specific elements, you can use the class or ID selectors */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Raleway', sans-serif;
}




/* Add any custom styles for the navigation bar */
.navBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(51, 51, 51, 0.7);
    /* Set the background color with opacity */
    backdrop-filter: blur(12px);
    /* Optional: Apply a blur effect to the background */
    padding: 10px;
    color: #fff;
    z-index: 100;
    /* Add a higher z-index to ensure the navbar appears above other elements */
}

/* Add styles for the logo image */
.logo {
    height: 81px;
}

/* Media query for smaller screens (e.g., mobile devices) */
@media (max-width: 768px) {
    .logo {
        height: 54px;
        /* Adjust this value for the logo height you want on mobile */
    }
}

/* Add styles for the navigation links */
.navLinks {
    list-style: none;
    display: flex;
    padding-top: 18px;
}


/* Add padding to the right of the navigation links */
.link {
    color: #fff;
    text-decoration: none;
    margin: 0 12px;
    padding-right: 12px;
    font-weight: 600;
    /* Add padding to the right */
}


/* Add margin to the content below the fixed navbar */
.content {
    margin-top: 160px;
    /* Adjust this value to add space below the fixed navbar */
}

/* Add margin to the slider container */
.slider-container {
    margin-top: 0px;
    /* Adjust this value to create space between the navbar and slider */
}

.slider-container .carousel .slide img {
    height: 600px;
    /* Set the height of the slider content */
}


/* Hamburger Menu Styles */
.menuButton {
    display: none;
    cursor: pointer;
    padding-right: 20px;

}

.menuIcon {
    width: 30px;
    height: 4px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
}

.menuIcon.open {
    transform: rotate(-45deg);
}

/* Show the navigation links when the menu is open */
.navLinks.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #333;
    width: 100%;
    text-align: center;
    z-index: 99;
}

.navLinks.show li {
    margin: 10px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .navLinks {
        display: none;
    }

    .menuButton {
        display: block;
    }

    .navBar.scrolling {
        top: 0;
    }
}


/* Style the title and subtitle on each slide */
.slide-title {
    font-size: 24px;
    color: #fff;
    margin: 10px 0;
}

.slide-subtitle {
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
}

/* Adjust the layout of each slide container */
.slide-container {
    position: relative;
}

/* Style the container for title and subtitle */
.slide-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}



/* MainPage.css */

/* Rest of the styles */

/* Custom styles for the scrollbar in webkit-based browsers (Google Chrome, Safari) */
/* Note: This may not work in all browsers and future browser updates may change the behavior. */
/* For Firefox, use similar styles with -moz-scrollbar pseudo-elements. */
::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: #003399;
    /* Color of the thumb (the draggable part) */
    border-radius: 5px;
    /* Rounded corners of the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #003399;
    /* Color of the thumb on hover */
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Color of the track (the area behind the thumb) */
    border-radius: 5px;
    /* Rounded corners of the track */
}

/* Add this CSS to your MainPage.css file or your preferred CSS file */
.main-content-container {
    padding: 45px;
}

.main-image-container img {
    max-width: 100%;
    height: auto;
}

/* Custom CSS to ensure the image stays in the same row as the text on larger screens */
@media (min-width: 992px) {
    .main-content-container .row {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }

    .main-content-container .col-lg-8 {
        flex: 2;
    }

    .main-content-container .col-lg-4 {
        flex: 1;
    }
}




.fullscreen-video {
    width: 100%;
    height: auto;
    object-fit: cover;
}


.avatar {
    display: flex;
    align-items: center;
}

.avatar img {
    width: 41px;
    height: 41px;
    border-radius: 50%;
    margin-right: 6px;
}

.avatar .user-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Add this CSS to your MainPage.css or your preferred stylesheet */
.fullscreen-video {
    width: 100%;
    height: 100vh;
    /* Adjust this height as needed */
    object-fit: cover;
}

/* MainPage.css */
.video-container {
    position: relative;
    width: 100%;
}

.video-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: white;
    font-weight: 600;
    text-align: center;

}

.video-subtitle {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: white;
    font-weight: 400;
    text-align: center;
    line-height: 1.2;
}

/* Media query for smaller screens (e.g., mobile devices) */
@media (max-width: 768px) {
    .video-title {
        font-size: 20px;
        line-height: 1;
        /* Adjust this value for the font size you want on mobile */
    }

    .video-subtitle {
        font-size: 12px;
        /* Adjust this value for the font size you want on mobile */
    }
}


/* Add parallax effect for the row background */
.parallax-row {
    background-image: url('./NRDRUHSHomeBanner.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;


}

/* Style the columns */
.column {
    padding: 20px;
    text-align: center;
    color: #fff;
    background-color: rgb(0, 0, 255, 0.4);
    border-radius: 8px;
    margin: 10px;
    width: 100%;
    /* Set the columns to stretch within the row */
}

.column h3 {
    font-size: 24px;
    margin-bottom: 20px;
}


/* Loader.css */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Add this CSS to your MainPage.css */

.scroll-to-top-btn {
    display: none;
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    font-size: 24px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    transition: opacity 0.3s;
}

.scroll-to-top-btn.show {
    display: block;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}